


































































































































import { defineComponent, onMounted, provide, Ref, ref, toRef, watch } from '@vue/composition-api'
import router from '@/router'
import { useState, useActions } from '@/shared/mixins/helpers'
import { useI18n } from 'vue-i18n-composable'
import apolloClient from '@/shared/services/ApolloCLientAPI'
import Table from '@/shared/components/Table.vue'
import BackendTable from '@/shared/components/table/BackendTable.vue'
import BackendTableFilters from '@/shared/components/BackendTableFilters.vue'
import { TableHeaderDTO } from '@/dto/tableHeaderDTO'
import utils from '@/shared/mixins/utils'
import generalData from '@/assets/data/general-data.json'
import { Status, UserRole } from '@/shared/enum/general-enum'
import riskQueries from '@/shared/queries/riskQueries'
import variables from '@/shared/variables'
import { TranslateResult } from 'vue-i18n'
import RiskAssessment from '@/components/risk-assessment/RiskAssessment.vue'
import riskAssessmentData from '@/assets/data/risk-assessment-data.json'
import {
    GetAssessmentsListQuery,
    RiskAssessmentListFieldsFragment as RiskAssessmentRow
} from "@/dto/types"
import { RISK_ASSESSMENTS_LIST_QUERY } from "@/shared/queries/GetRiskAssessments"
import { SortOrderDTO } from '@/dto/sortOrderDTO'

const getRiskAssessmentsList = async (): Promise<RiskAssessmentRow[]> => {
    const response = await apolloClient.apolloClient.query<GetAssessmentsListQuery>({
        query: RISK_ASSESSMENTS_LIST_QUERY,
        fetchPolicy: 'no-cache',
    })
    return response.data.risksTable.assessments.items ?? []
}


export default defineComponent({
    name: 'RiskAssessments',
    components: { Table, BackendTable, BackendTableFilters, RiskAssessment },
    props: {
        loggedInUser: {
            type: String,
        },
        validationMode: {
            default: false,
        },
    },

    setup(props) {
        const riskassessments = ref([])
        const { t } = useI18n()
        const { language } = useState(['language'])
        const tableHeaders: Ref<Array<TableHeaderDTO>> = ref([])
        const loading = ref(false)
        const clientConfigData = ref(null)
        const { role } = useState(['role'])
        const noDataMessage: Ref<TranslateResult> = ref('')
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        const { menuOpen } = useState(['menuOpen'])
        const { SET_OPEN_MENU } = useActions(['SET_OPEN_MENU'])
        const { SET_LEFT_MENU_SIDEBAR } = useActions(['SET_LEFT_MENU_SIDEBAR'])
        const displayRiskAssessment = ref(false)
        const tableCmp = ref(null)
        const riskAssessmentRecordNo = ref(null)
        const lastRecordNo = ref(null)
        const filterCount = ref(0)
        const filteredRecordCount = ref(0)
        const searchValue = ref('')
        const netChanceDashboard = ref(router.app.$route.query.netChance)
        const netImpactDashboard = ref(router.app.$route.query.netImpact)
        const status: Ref = ref([])
        const riskAssessDefaultFilters = ref({})
        const infomationOp = ref(null)
        const totalPages = ref(0)
        const alertColor = ref(router.app.$route.query.alertColor)
        const validationMenu = ref(false)
        const firstRecordNo = ref(null)
        const assessmentId = ref(null)

        const tableSortOrder = ref([
            { sortField: 'refId', direction: 'asc' },
        ]) as Ref<SortOrderDTO>

        provide('clientConfigData', clientConfigData)
        // Make validation mode available to children in sidebar
        provide('VIEW_VALIDATION_MODE', toRef(props, 'validationMode'))

        // convert id values to name values
        const convertIdToNames = (state: number) => {
            const stateVal = utils.idToName(generalData.STATUS, state)

            return t(stateVal, language.value)
        }
        const setDefaultFilters = () => {
            riskAssessDefaultFilters.value = {}
            if (router.app.$route.query.onlyLatest === "true")
                riskAssessDefaultFilters.value['isLatest'] = ['RISK_ASSESSMENT_IS_LATEST']
            if (router.app.$route.query.riskSet)
                riskAssessDefaultFilters.value['riskSet'] = [router.app.$route.query.riskSet]
            if (alertColor.value)
                riskAssessDefaultFilters.value['alertColorName'] = alertColor.value
            if (netChanceDashboard.value)
                riskAssessDefaultFilters.value['netChance'] = [Number(netChanceDashboard.value)]
            if (netImpactDashboard.value)
                riskAssessDefaultFilters.value['netImpact'] = [Number(netImpactDashboard.value)]
            if (props.loggedInUser && role.value === UserRole.EMPLOYEE)
                riskAssessDefaultFilters.value['fexecutedBy'] = [props.loggedInUser]
            if (status.value)
                riskAssessDefaultFilters.value['status'] = status.value
        }

        // set table headers
        const setTableHeaders = () => {
            tableHeaders.value = [
                {
                    header: t('NUMBER_SHORT', language.value),
                    sort: true,
                    fieldName: 'refId',
                    style: 'min-width: 210px;',
                    alert: true,
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_TOPIC', language.value),
                    sort: true,
                    fieldName: 'description',
                    style: 'width: 130px;',
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_DESCRIPTION', language.value),
                    sort: true,
                    fieldName: 'fullDescription',
                    limit:200,
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_EXECUTED_BY', language.value),
                    sort: true,
                    fieldName: 'fexecutedBy',
                    style: 'width: 150px;',
                    bodyStyle: 'text-align: center',
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_NET_RISK', language.value),
                    sort: true,
                    fieldName: 'netRisk',
                    style: 'width: 150px;',
                    bodyStyle: 'text-align: center',
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_STATUS', language.value),
                    sort: true,
                    fieldName: 'status',
                    style: 'width: 150px; text-align: center',
                    bodyStyle: 'text-align: center',
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_APPROVED_ON', language.value),
                    sort: true,
                    fieldName: 'fapprovalDate',
                    style: 'width: 150px;',
                    type: 'date',
                },
                {
                    header: t('RISK_ASSESSMENTS_TABLE_COLUMN_APPROVED_BY', language.value),
                    sort: true,
                    fieldName: 'fapprovedBy',
                    style: 'width: 150px;',
                },
            ]
        }

        // open information overlay panel
        const toggleInformation = (event: object) => {
            infomationOp.value.toggle(event)
        }

        const getApprovedBy = (riskAssessment) => {
            if (!riskAssessment.approvedBy) {
                return ''
            }
            return riskAssessment.approvedBy.firstName + ' ' + riskAssessment.approvedBy.lastName
        }

        // get client config tolerance data
        const getClientConfig = async () => {
            const clientConfig = `
                query{
                    organisationconfig(orgId:${organisationId}){
                        ${riskQueries.CLIENT_CONFIGURATION_DETAILS}
                    }
                }
            `

            const result = await apolloClient.getGraphqlData(clientConfig)
            if (result) {
                const clientConfigResult = result.data.organisationconfig
                clientConfigData.value = clientConfigResult
            }
        }

        // updating riskassessments array with additional fields and setting description content
        const toTableFormat = (ras) => {
            riskassessments.value = []
            ras.map(riskAssessment => {
                const ra = {
                    id: riskAssessment.id,
                    no: 0,
                    description: '',
                    fullDescription: '',
                    fexecutedBy: '',
                    netRisk: 0,
                    netRiskStyleClass: '',
                    fapprovalDate: null,
                    fapprovedBy: '',
                    status: null,
                    color: '',
                    alertColorName: '',
                    info: '',
                    infoKey: '',
                    isLatest: '',
                    riskSet: {}
                }
                // creating a copy of object to hold formatted and extra attributes
                Object.assign(ra, riskAssessment)
                ra.isLatest = riskAssessment.isLatest ? 'RISK_ASSESSMENT_IS_LATEST' : 'RISK_ASSESSMENT_IS_NOT_LATEST'
                ra.no = utils.padLeft('000', riskAssessment.order)
                ra.description = riskAssessment.risk.description
                ra.fullDescription = riskAssessment.risk.fullDescription
                ra.fexecutedBy =
                    riskAssessment.executedBy &&
                    riskAssessment.executedBy.firstName + ' ' + riskAssessment.executedBy.lastName
                ra.netRisk = riskAssessment.netChance * riskAssessment.netImpact
                ra.fapprovalDate = riskAssessment.approvalDate
                ra.fapprovedBy = getApprovedBy(riskAssessment)
                ra.netRiskStyleClass = utils.netRiskStyleClass(ra.netRisk, clientConfigData.value)
                ra.status = convertIdToNames(riskAssessment.state)
                const alertsMenuType: string = props.validationMode ? 'menu-validation' : 'not-validation-mode'
                ra.color = utils.getAlertInfo(alertsMenuType, riskAssessment).color
                ra.info = utils.getAlertInfo(alertsMenuType, riskAssessment).info
                ra.infoKey = utils.getAlertInfo(alertsMenuType, riskAssessment).infoKey
                // get the name of alert color code in order to use in filter
                ra.alertColorName = utils.getAlertInfo(alertsMenuType, riskAssessment).alertColorName
                ra.riskSet = riskAssessment.risk.riskSet.name ?? ""
                riskassessments.value.push(ra)
            })
        }

        const getRiskAssessments = async (params) => {
            loading.value = true

            try {
                const assessments = await getRiskAssessmentsList()
                totalPages.value = 1
                filteredRecordCount.value = assessments.length
                await getClientConfig()
                toTableFormat([...assessments].sort((a, b) => (a.refId > b.refId ? 1 : -1)))
                loading.value = false
            } catch (err) {
                loading.value = false
                throw Error(`Error while retrieving risk assessments`)
            }
            finally {
                loading.value = false;
            }

        }

        // go to risk-assessment record
        const goToRecord = async (params) => {
            if (tableCmp.value) {
                /* get the index of selected details on `copyOfTableData`.
            `riskassessments` cannot be use here as when the filter/ sort is in use, list is getting updated */
                const record = utils.getRecordByIndex(
                    tableCmp.value.copyOfTableData,
                    params.type,
                    params.no,
                    tableCmp.value
                )
                // find the risk assessment id (of previous/ next) assessment
                assessmentId.value = riskassessments.value.find(
                    (assessment) => assessment.no === record.no
                ).id

                riskAssessmentRecordNo.value = record.no
            }
        }

        // update list after assessment is created/updated
        const updateListPostAssessment = async () => {
            await getRiskAssessments({ pageNum: 1 })
        }
        // risk assessment slideout
        const openRiskAssessmentPopup = async (id) => {
            displayRiskAssessment.value = true
            assessmentId.value = riskassessments.value.find(
                (assesssment) => assesssment.id === id
            ).id
            riskAssessmentRecordNo.value = riskassessments.value.find(
                (assessment) => assessment.id == id
            ).no
            // check for the `tableCmp` reference existance
            if (!tableCmp.value) {
                return
            }
            // set last record no
            lastRecordNo.value = utils.getLastRecord(tableCmp.value.copyOfTableData)
            // set first record no
            firstRecordNo.value = tableCmp.value.copyOfTableData[0].no
        }

        // set no data message based on logged in user roles
        const setNoDataMessageForRoles = () => {
            let message
            switch (role.value) {
                case UserRole.EMPLOYEE:
                    message = 'NO_RISK_ASSESSMENTS_TABLE_DATA_AVAILABLE_FOR_USER_MESSAGE'
                    break
                case UserRole.VALIDATOR:
                    message = 'NO_RISK_VALIDATION_ASSESSMENTS_TABLE_DATA_AVAILABLE_FOR_USER_MESSAGE'
                    break
                default:
                    'No role found'
                    break
            }
            noDataMessage.value = t(message, language.value)
        }

        // set filters count
        const setCount = (count) => {
            filterCount.value = count
        }

        // display filter section
        const viewFilterSection = () => {
            tableCmp.value.setTableHeight()
        }

        // update table records count
        const updateRecordCount = (recordsCount) => {
            filteredRecordCount.value = recordsCount
        }

        // set searched records count
        const searchCount = (count) => {
            filterCount.value = 0
            filteredRecordCount.value = count
        }

        // close sidebar on escape key press
        const closeSidebarEsc = () => {
            // listening to escape key press
            document.addEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    //close assessment sidebar if it's open
                    if (displayRiskAssessment.value) {
                        displayRiskAssessment.value = false
                    }
                }
            })
        }

        // set values based on query params
        const setQueryParamConfig = () => {
            // active menu based on menu headers
            validationMenu.value = props.validationMode
            if (validationMenu.value) {
                status.value = [Status.submitted, Status.approved, Status.declined]
            } else {
                status.value = []
            }
            // setting filter values from query params
            netChanceDashboard.value = router.app.$route.query.netChance
            netImpactDashboard.value = router.app.$route.query.netImpact
            alertColor.value = router.app.$route.query.alertColor
            status.value = Array.isArray(router.app.$route.query.status)
                ? [...router.app.$route.query.status]
                : router.app.$route.query.status ? [router.app.$route.query.status] : [];
        }

        watch(
            () => router.app.$route.query,
            async () => {
                setQueryParamConfig()
                await getRiskAssessments({ pageNum: 1 })
                setDefaultFilters()
            },
            {
                immediate: true,
            }
        )

        // watch for language change from store and update table headers by calling setTableHeaders
        watch(language, () => {
            setTableHeaders()
            toTableFormat(riskassessments.value)
            setNoDataMessageForRoles()
        })

        // watch for loggedInUser router prop which is coming from tiles page
        watch(
            () => props.loggedInUser,
            () => {
                getRiskAssessments({ pageNum: 1 })
            }
        )

        onMounted(async () => {
            closeSidebarEsc()
            setTableHeaders()
            setNoDataMessageForRoles()
        })

        return {
            riskassessments,
            getRiskAssessments,
            t,
            language,
            utils,
            tableHeaders,
            displayRiskAssessment,
            loading,
            variables,
            role,
            noDataMessage,
            SET_OPEN_MENU,
            SET_LEFT_MENU_SIDEBAR,
            menuOpen,
            openRiskAssessmentPopup,
            goToRecord,
            tableCmp,
            lastRecordNo,
            riskAssessmentRecordNo,
            filterCount,
            setCount,
            viewFilterSection,
            filteredRecordCount,
            updateRecordCount,
            searchValue,
            searchCount,
            riskAssessmentData,
            status,
            riskAssessDefaultFilters,
            infomationOp,
            toggleInformation,
            totalPages,
            updateListPostAssessment,
            validationMenu,
            firstRecordNo,
            assessmentId,
        }
    },
})
