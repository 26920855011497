import { gql } from "@apollo/client/core"

export const RISK_ASSESSMENT_LIST_FRAGMENT = gql`
fragment RiskAssessmentListFields on RiskAssessment {
    id
    alertColor{
        color
        info
        infoKey
    }
    approvalDate
    approvedBy {
        username
        firstName
        lastName
    }
    executedBy {
        username
        firstName
        lastName
    }
    executionDate
    netChance
    netImpact
    nextRsaDate
    order
    refId
    risk {
        id
        description
        fullDescription
        riskSet {
          id
          name
        }
    }
    state
    validationAlertColor{
        color
        info
        infoKey
    }
    isLatest
}
`

export const RISK_ASSESSMENTS_LIST_QUERY = gql`
query GetAssessmentsList {
  risksTable {
    assessments {
      items {
        ...RiskAssessmentListFields
      }
    }
  }
}
# Include fragment in query
${RISK_ASSESSMENT_LIST_FRAGMENT}
`